.flex {
  flex: 1 0 auto;
}

.layout {
  margin: 0 auto;
  padding: 0 1.5rem;
  max-width: 1440px;
}

.layout-column {
  display: flex;
  flex-direction: column;
}

.layout-row {
  display: flex;
  flex-direction: row;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.inline-flex {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
