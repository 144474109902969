@use 'variables';
@use 'mixins' as *;

.ngneat-dialog-content {
  border-radius: 1rem !important;
  overflow: visible !important;

  @include media-breakpoint-up(md) {
    .ngneat-close-dialog {
      transform: translateX(3rem) translateY(-6px);
      background-color: variables.$greyColor;
    }
  }
}

.onboarding-dialog {
  .ngneat-dialog-backdrop {
    padding: 0;

    .ngneat-dialog-content {
      height: 100% !important;
      border-radius: 0px !important;

      @include respond-to(md) {
        height: auto !important;
        border-radius: 1rem !important;
        max-width: 500px;
      }
    }

    .ngneat-close-dialog {
      display: none;
    }
  }
}

.confirm-dialog {
  .ngneat-close-dialog {
    display: none;
  }
}

.filters-dialog {
  .ngneat-dialog-backdrop {
    padding: 0;
    align-items: center;
    justify-content: flex-end;

    .ngneat-dialog-content {
      min-height: 100vh;
      border-radius: 0px !important;

      @include respond-to(md) {
        border-radius: 1rem !important;
        max-width: 600px;
      }
    }

    .ngneat-close-dialog {
      display: none;
    }
  }
}

.jit-modal-dialog {
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  z-index: 1100;
  background-color: transparent;

  &.is-opened {
    display: flex;
  }

  &__window {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 500px;
    padding: 24px 24px 32px 24px;
    border-radius: 16px;
    background: #FFF;

    @include resolution(sm) {
      width: 90%;
    }
  }

  &__header {
    width: 100%;
    padding: 0;
    margin: 0;
    color: #1A396A;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:empty {
      display: none;
    }
  }

  &__content {
    width: 100%;
    padding: 0;
    margin: 0;
    color: #1A396A;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 32px;
    width: 100%;
    padding: 0;
    margin: 0;

    &:empty {
      display: none;
    }

    .button-default {
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1px solid #1A396A;
      background: #FFF;
      color: #1A396A;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.16px;
      cursor: pointer;
    }

    .button-active {
      display: flex;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background: #EF123A;
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.16px;
      cursor: pointer;
    }
  }

  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;

    &.is-opened {
      display: block;
    }
  }
}
