/*
@styleguide

@title Component: Navbar

Documentation and EXDS components incomplete; see <a href="https://getbootstrap.com/docs/5.1/components/navbar/" target="_blank">Bootstrap's navbar details</a>.

*/

#nav {
    
    img{
        height:31px;
        width: auto;
    }

    &.navbar-light {
        background-color: white;
    }

    .nav-link {
        color: $blue-800 !important;
        font-weight: 500;
        font-size: 18px;
        padding: 8px;
    }
    
    .nav-button {
        color: $blue-800 !important;
        font-weight: 500;
        font-size: 18px;
    }

    #searchInput {
        background-color: $gray-100;
        border: 2px solid $grayblue-100;
        border-radius: 50rem;
        width: 360px;
    }

    .dropdown-item:hover{
        background-color: transparent;
    }

    .dropdown-item:focus {
        background-color: $gray-200;
    }
    .dropdown-link:focus {
        background-color: $gray-200;
    }

    a {
        text-decoration: none;
    }

    .dropdown-link {
        color: $blue-800 !important;
        font-weight: $navbar-weight-mobile;
        font-size: 18px;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    .form-control:focus {
        box-shadow: 0 0;
        border-color: transparent;
    }

    .chat-badge {
        background-color: $gray-200;
        color: $grayblue-500;
    }

    .nav-button {
        padding: 8px;
        border: 0px;
    }

    .container-fluid {
        justify-content: start;
    }

    .active a{
        background-color: #F0F8FD;
        border-radius: 100px;
    }

    .dropdown-divider {
        display: none;
    }

    .dropdown-menu {
        border: 0px;
    }

    .dropdown-menu.show {
        margin-top: 0.25rem;
    }
    .contact-wrapper {
        list-style: none;
        font-size: 14px;
    }

    .btn.nav-button.show svg.icon-simple-caret-down-outline{
        transform: rotate(180deg);
        // transition: transform .35s ease;
    }

    .chat-status-indicator {
        display: inline-block;
        border-radius: 50%;
        width: 8px;
        height: 8px;
    }

    .chat-online {
        background-color: $green-500;
    }

    .chat-offline {
        background-color: $red-400;
    }

    //get rid of this later - the hamburger icon is outlined with black and it's hideous and i can't look at it
    .navbar-toggler:focus {
        box-shadow: 0 0 0 0.25rem rgb(0 102 152 / 25%);
    }
    .offcanvas {
        max-width: 100%;
    }
    // 188px is the width of the offcanvas container, minus the icon width and padding and everything else
    .user-profile-name {
        max-width: 188px;
        overflow: hidden;
        white-space: nowrap;
    }
    
    .recently-viewed-header {
        h3 {
            color: $blue-800;
        }
    }
    
    .recently-viewed-item a{
        color: $blue-800;
    }

    #recentlyViewed {
        overflow-y: auto;
    }

    #global-search-form-w ul > li {
        cursor:pointer;
    }
    #navbarSupportedContent{
        overflow-y: auto;
    }
}

@include media-breakpoint-between(sm, xl){
    #nav {
        .offcanvas {
            max-width: 320px;
        }
        
    }
}

//This adjusts for the header so the footer doesn't get pushed out of the viewport
$navbar-height:64px;
$navbar-height-desktop:80px;

.content-container {
    height: calc(100vh - $navbar-height);
}

@include media-breakpoint-up(xl) {
    .content-container {
        height: calc(100vh - $navbar-height-desktop);
    }

    #nav {
        &.navbar-light {
            height:$navbar-height-desktop;
        }
    }
    #nav {
        img {
        height: 50px;
        width: auto;
        }
        .dropdown-link {
            color: $blue-800 !important;
            
            font-size: 16px;
            padding-bottom: 0.25rem;
            padding-top: 0.25rem;
        }
        .dropdown-divider {
            display: block;
        }
        .dropdown-menu {
            border: 1px solid #DEE2E7;
        }
        .active a {
            background-color: #D3E9F8;
        }
        .offcanvas {
            flex-direction: row;
        }
        #helpDropdownMenu {
            min-width: 250px;
        }
        #recentlyViewed {
            min-width: 250px;
            max-height: 500px;
        }
        #recentlyViewedPlaceholder {
            width: 230px;
            height: 250px;
        }
        #navbarSupportedContent{
            overflow-y: visible;
        }
        //make the button that opens the profile dropdown on desktop be a minimum width 
        //so that opening the help, recently viewed, or profile dropdowns doesn't cause scrollbars 
        //by opening off of the screen
        #desktopProfileDropdownWrap {
            min-width: 9.875rem;
            text-align: left;
        }
    }
}

