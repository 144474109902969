/*
@styleguide

@title Component: Simple Icons

Right now we only provide one set of icons, the simple icon set. The icons in this set use the current text color as their only color. These icons are intended to be referenced from an external SVG sprite, like so:

    <svg class="icon-simple-NAME" role="img" >
        <title> NAME icon </title>
        <use xlink:href="PATH/icons-simple.svg#NAME"></use>
    </svg>

Replace PATH with the path to icons-simple.svg (this is the sprite sheet), and NAME with one of the icon names. See <a href="simple-icons-list.html" target="_blank">this documentation</a> for a list of names and corresponding icons that can be used by referencing this spritesheet.

Alternatively, drop the entire contents of dist\images\icons\icons-simple.svg into an inline sprite to reference on every page:

    <div style="visibility: hidden; position: absolute; z-index: -1;">
        *contents of dist\images\icons\icons-simple.svg*
    </div>

Then the code for a single icon looks something like this:

    <svg class="icon-simple-NAME" role="img" >
        <title> NAME icon </title>
        <use xlink:href="#NAME"></use>
    </svg>

Pre-existing size classes in the form of .icon-sz-NUMBERs should be placed on the SVG element for icon sizing (where NUMBER is the icon's width and height in pixels). The default size(the icon size if no size is specified) is 24 by 24px, or 1.5 by 1.5rem.

    <svg class="icon-simple-NAME icon-sz-NUMBERs" role="img" >
        <title> NAME icon </title>
        <use xlink:href="PATH/icons-simple.svg#NAME"></use>
    </svg>

Available sizing classes:

- .icon-sz-14s
- .icon-sz-16s
- .icon-sz-18s
- .icon-sz-20s
- .icon-sz-24s (default)
- .icon-sz-28s
- .icon-sz-32s
- .icon-sz-40s
- .icon-sz-48s
- .icon-sz-96s

To change the color of the icons, use Bootstrap's <a href="https://getbootstrap.com/docs/5.1/utilities/colors/" target="_blank">color utility classes</a> on the SVG element.

    <svg class="icon-simple-NAME icon-sz-NUMBERs text-primary" role="img" >
        <title> NAME icon </title>
        <use xlink:href="PATH/icons-simple.svg#NAME"></use>
    </svg>

To add icons, save out a 24px by 24px icon as an SVG (the sizing refers to the icon's container; the actual icon will likely be smaller and centered horizontally and vertically in the 24 by 24 space -- that's expected) to this project at <em>src/images/icons/icons-simple/</em>.

Then run the command 'npm run icons' in this project's root folder to regenerate the spritesheet file (<em>/dist/images/icons/icons-simple.svg</em>) and the <a href="simple-icons-list.html" target="_blank">documentation</a> that describes the spritesheet contents.

Refresh the <a href="simple-icons-list.html" target="_blank">documentation</a> and check that the new icon is present and looks correct (if not, you may have to open the SVG in a text editor and change some attributes around, or edit it in a graphics program like Figma, Sketch, Edge, or Illustrator and re-export it).

Then copy and paste the new spritesheet file over the old spritesheet file wherever it lives in the solution you are working on.

*/




/*ICON-SIMPLE icon set*/
/*these icons are a single color that is inherited from the font color... so if you want to change the color to the primary theme color, add the utility class .text-primary*/
svg[class*=icon-simple] {
    display: inline-block;
    @extend .icon-sz-24s; //we have to have a default size... it's 24 by 24px
}



/*ICON SIZING UTILITY CLASSES*/
/*for square icons*/
/*default size*/
.icon-sz-24s {
    width: 1.5rem;
    height: 1.5rem;
}
/*a bunch of other sizes in this map*/
$icon-sizes-square: (
    "14": 0.875rem,
    "16": 1rem,
    "18": 1.125rem,
    "20": 1.25rem,
    "28": 1.75rem,
    "32": 2rem,
    "40": 2.5rem,
    "48": 3rem,
    "96": 6rem
);
/*use the map to generate the rest of the sizing classes*/
@each $size, $real-size in $icon-sizes-square {
    .icon-sz-#{$size}s {
        width: $real-size !important;
        height: $real-size !important;
    }
}