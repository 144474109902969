/*
@styleguide

@title Component: Buttons

Very similar to <a href="https://getbootstrap.com/docs/5.1/components/buttons/" target="_blank">Bootstrap's buttons</a>, ours feature fully rounded corners and a lack of a :hover state.

<button type="button" class="btn btn-primary">Primary Button</button> <button type="button" class="btn btn-secondary">Secondary Button</button> <button type="button" class="btn btn-success">Success Button</button> <button type="button" class="btn btn-warning">Warning Button</button> <button type="button" class="btn btn-danger">Danger Button</button> <button type="button" class="btn btn-info">Info Button</button> <button type="button" class="btn btn-light">Light Button</button> <button type="button" class="btn btn-dark">Dark Button</button> <button type="button" class="btn btn-link">Link Button</button> <button type="button" class="btn">Button</button>

    <button type="button" class="btn btn-primary">Primary Button</button>
	<button type="button" class="btn btn-secondary">Secondary Button</button>
	<button type="button" class="btn btn-success">Success Button</button>
	<button type="button" class="btn btn-warning">Warning Button</button>
	<button type="button" class="btn btn-danger">Danger Button</button>
	<button type="button" class="btn btn-info">Info Button</button>
	<button type="button" class="btn btn-light">Light Button</button>
	<button type="button" class="btn btn-dark">Dark Button</button>
	<button type="button" class="btn btn-link">Link Button</button>
	<button type="button" class="btn">Button</button>

<button type="button" class="btn btn-outline-primary">Primary Outline Button</button> <button type="button" class="btn btn-outline-secondary">Secondary Outline Button</button> <button type="button" class="btn btn-outline-success">Success Outline Button</button> <button type="button" class="btn btn-outline-warning">Warning Outline Button</button> <button type="button" class="btn btn-outline-danger">Danger Outline Button</button> <button type="button" class="btn btn-outline-info">Info Outline Button</button> <button type="button" class="btn btn-outline-light">Light Outline Button</button> <button type="button" class="btn btn-outline-dark">Dark Outline Button</button>

	<button type="button" class="btn btn-outline-primary">Primary Outline Button</button>
	<button type="button" class="btn btn-outline-secondary">Secondary Outline Button</button>
	<button type="button" class="btn btn-outline-success">Success Outline Button</button>
	<button type="button" class="btn btn-outline-warning">Warning Outline Button</button>
	<button type="button" class="btn btn-outline-danger">Danger Outline Button</button>
	<button type="button" class="btn btn-outline-info">Info Outline Button</button>
	<button type="button" class="btn btn-outline-light">Light Outline Button</button>
	<button type="button" class="btn btn-outline-dark">Dark Outline Button</button>

<button type="button" class="btn btn-primary btn-lg">Large Button</button> <button type="button" class="btn btn-primary btn-sm">Small Button</button>

	<button type="button" class="btn btn-primary btn-lg">Large Button</button>
	<button type="button" class="btn btn-primary btn-sm">Small Button</button>

If buttons without fully rounded corners are needed, border radius utility classes can be used.

<button type="button" class="btn btn-primary rounded-0">Square Button</button> <button type="button" class="btn btn-primary rounded-1">Button with 4px border radius</button> <button type="button" class="btn btn-primary rounded-2">Button with 16px border radius</button>

	<button type="button" class="btn btn-primary rounded-0">Square Button</button>
	<button type="button" class="btn btn-primary rounded-1">Button with 4px border radius</button>
	<button type="button" class="btn btn-primary rounded-2">Button with 16px border radius</button>

*/





//
// Alternate button mixins
// Bootstrap uses some calculations to get hover, active, and disabled state colors in _buttons.scss, 
// but we have human-selected ones, so we may need to remove some of the calculations/colors and add custom colors back in
//

// This mixin overwrites Bootstrap's button hover state so that it uses the same colors as the regular state
// We can also add in mixins later to counter the active and disabled states if we want to

// scss-docs-start button-variant-hover-neutralize-mixin
@mixin button-variant-hover-neutralize(
  $background,
  $border,
  $color: color-contrast($background)
) {

  &:hover {
    color: $color;
    @include gradient-bg($background);
    border-color: $border;
  }

  .btn-check:focus + &,
  &:focus {
    color: $color;
    @include gradient-bg($background);
    border-color: $border;
  }
}
// scss-docs-end btn-variant-hover-neutralize-mixin

@mixin button-outline-variant-hover-neutralize(
  $color
) {

  &:hover {
    color: $color;
    border-color: $color;
	background-color: transparent;
  }

  .btn-check:focus + &,
  &:focus {
    color: $color;
    border-color: $color;
  }
}


//
// Alternate button loops
//

// scss-docs-start btn-variant-hover-neutralize-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant-hover-neutralize($value, $value);
  }
}
// scss-docs-end btn-variant-hover-neutralize-loops

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
	  @include button-outline-variant-hover-neutralize($value);
	}
  }




//
// Ghost button - DEPRECATED.
// .btn-outline-secondary should be used instead of .btn-ghost. 
// .btn-outline-primary should be used instead of  .btn-ghost-primary
//

.btn-ghost{
  background-color: transparent;
  color: $blue-800;
  border: 1px solid $blue-800;
}

.btn-ghost-primary {
  background-color: transparent;
  color: $blue-700;
  border: 1px solid $blue-700;
  &:hover {
    color: $blue-700;
  }
}

.btn-group {
  // Prevent double borders when buttons are next to each other
  > .btn-ghost:not(:first-child),
  > .btn-ghost-primary:not(:first-child) {
    margin-left: -1px;
  }
}