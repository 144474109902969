@use 'variables';

.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background: none;
}

.mdc-list-item--selected .mdc-list-item__primary-text {
  color: inherit;
}
.mat-mdc-form-field-focus-overlay {
  background-color: #fff;
}
.mdc-line-ripple--active {
  display: none !important;
}
.mat-mdc-text-field-wrapper {
  border: 1px solid variables.$mainColor;
  border-radius: 8px !important;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: variables.$secondaryText;
}

.mdc-text-field .mdc-floating-label {
  top: 34px !important;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem !important;
}

.mdc-text-field .mdc-text-field__input::placeholder {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: rgba(26, 57, 106, 0.5) !important;
}

.mdc-text-field .mdc-text-field__input {
  font-size: 1.125rem !important;
  line-height: 1.25rem !important;
  font-weight: 600 !important;
  color: variables.$mainText !important;
  transform: translateY(0.5rem);
}

.mdc-text-field .mdc-line-ripple {
  display: none;
}

.cdk-overlay-pane {
  overflow: hidden;
  box-shadow: 0 2px 16px 0 #07235040;
  border-radius: 8px !important;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above) {
  margin-top: 5px;
}

.mdc-menu-surface.mat-mdc-select-panel,
.mdc-menu-surface.mat-mdc-autocomplete-panel {
  border-radius: 8px !important;
  border: 1px solid variables.$mainColor;
}

.mat-mdc-option {
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;

  .mdc-list-item__primary-text {
    font-weight: 600 !important;
  }
}

.mat-pseudo-checkbox {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.mat-pseudo-checkbox-full {
  background-color: variables.$grey;
  border-color: transparent !important;
  border-radius: 0.25rem !important;
}

.mat-pseudo-checkbox-full::after {
  color: #8b8b8b;
}

.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: variables.$mainText !important;
  width: 100%;
}

.match-text {
  background-color: #19acff4d;
  display: inline-flex;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.accordion-button {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
}
.results {
  &-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    padding: 0 14px 14px 14px;
  }
}

.notif-wrapper {
  position: sticky;
  bottom: 0;
  left: -35px;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: inherit;
  --mdc-snackbar-supporting-text-line-height: 1;
  --mdc-snackbar-supporting-text-size: 1rem;
  --mdc-snackbar-supporting-text-weight: 600;
  --mdc-snackbar-container-color: #1a396a;
  --mdc-snackbar-supporting-text-color: #fff;

  margin: 0 !important;
}

.mat-expansion-panel-header.mat-expanded:hover {
  background: transparent;
}

.mat-content {
  overflow: visible !important;
}

.form-check-input:checked {
  border-color: variables.$mainText;
  background-color: variables.$mainText;
}
