
.progress {
    border: 1px solid $grayblue-600;
    border-radius: 4px;
    height: 8px;
}
.progress-bar {
    &-under {
        background-color: $red-500;
        border-width: 0px 1px 0px 0px;
        border-color: $grayblue-600;
        border-style: solid;
    }
    &-active {
        background-color: $blue-500;
        border-width: 0px 1px 0px 0px;
        border-color: $grayblue-600;
        border-style: solid;
    }
    &-met_goal {
        background-color: $green-500;
        border-width: 0px 1px 0px 0px;
        border-color: $grayblue-600;
        border-style: solid;
    }
    &-over {
        background-color: $green-600;
    }
}
// .data-panel-hidden {
//     background-color: transparent;
// }
