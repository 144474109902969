/*
@styleguide

@title Component: Alerts

These components are very similar to Bootstrap's <a href="https://getbootstrap.com/docs/5.1/components/alerts/" target="_blank">alerts</a>, and use Bootstrap's existing alert class names. But the color rules for these alerts are different from Bootstrap's.

We have also added more child utility classes to enable responsive behaviors. The alert begins as a single column on mobile devices, with the alert title, message, and button taking up an entire row each in the alert. The close icon is located a certain distance from the top right corner of the alert. At the $alert-breakpoint, the single column changes to three columns, where the alert-col-msg is set to expand and the other columns are set to contract.

- .alert-col-msg (a wrapper for the alert text, including header and .alert-col-content elements; aligns the text on desktop and mobile layouts)
- .alert-col-content (a wrapper for the alert content, used to remove the margin on the last child element for proper spacing)
- .alert-col-btn (a wrapper for the alert 'Learn More' button .alert-btn; aligns the button on desktop and mobile layouts)
- .alert-col-close (a wrapper for the close icon button .alert-close; aligns the button on desktop and mobile layouts)
- .alert-btn (visually styles the 'Learn More' button)
- .alert-close (visually styles the close icon button)!

Only the primary/info alert was officially vetted by Istation UX. If you use any of the others, they might need additional work!

<div class="alert alert-primary alert-dismissable fade show" role="alert" >
  <div class="row position-relative">
      <div class="alert-col-msg">
          <h3 class="fs-4 fw-bold">Primary Alert Example Title</h3>
          <div class="mb-2 mb-lg-0 alert-col-content">
              Primary Alert Example Text
          </div>
      </div>
      <div class="alert-col-btn">
          <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
      </div>
      <div class="alert-col-close">
          <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
              <svg class="icon-simple-x-filled" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z" fill="currentColor"/>
              </svg>
          </button>
      </div>
  </div>
</div>

    <div class="alert alert-primary alert-dismissable fade show" role="alert" >
        <div class="row position-relative">
            <div class="alert-col-msg">
                <h3 class="fs-4 fw-bold">Primary Alert Example Title</h3>
                <div class="mb-2 mb-lg-0 alert-col-content">
                    Primary Alert Example Text
                </div>
            </div>
            <div class="alert-col-btn">
                <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
            </div>
            <div class="alert-col-close">
                <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
                    <svg class="icon-simple-x-filled" role="img">
                        <title>Close</title>
                        <use xlink:href="icons-simple.svg#x-filled"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>

<div class="alert alert-secondary alert-dismissable fade show" role="alert" >
  <div class="row position-relative">
      <div class="alert-col-msg">
          <h3 class="fs-4 fw-bold">Secondary Alert Example Title</h3>
          <div class="mb-2 mb-lg-0 alert-col-content">
              Secondary Alert Example Text
          </div>
      </div>
      <div class="alert-col-btn">
          <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
      </div>
      <div class="alert-col-close">
          <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
              <svg class="icon-simple-x-filled" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z" fill="currentColor"/>
              </svg>
          </button>
      </div>
  </div>
</div>

    <div class="alert alert-secondary alert-dismissable fade show" role="alert" >
        <div class="row position-relative">
            <div class="alert-col-msg">
                <h3 class="fs-4 fw-bold">Secondary Alert Example Title</h3>
                <div class="mb-2 mb-lg-0 alert-col-content">
                    Secondary Alert Example Text
                </div>
            </div>
            <div class="alert-col-btn">
                <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
            </div>
            <div class="alert-col-close">
                <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
                    <svg class="icon-simple-x-filled" role="img">
                        <title>Close</title>
                        <use xlink:href="icons-simple.svg#x-filled"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>

<div class="alert alert-success alert-dismissable fade show" role="alert" >
  <div class="row position-relative">
      <div class="alert-col-msg">
          <h3 class="fs-4 fw-bold">Success Alert Example Title</h3>
          <div class="mb-2 mb-lg-0 alert-col-content">
              Success Alert Example Text
          </div>
      </div>
      <div class="alert-col-btn">
          <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
      </div>
      <div class="alert-col-close">
          <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
              <svg class="icon-simple-x-filled" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z" fill="currentColor"/>
              </svg>
          </button>
      </div>
  </div>
</div>

    <div class="alert alert-success alert-dismissable fade show" role="alert" >
    <div class="row position-relative">
        <div class="alert-col-msg">
            <h3 class="fs-4 fw-bold">Success Alert Example Title</h3>
            <div class="mb-2 mb-lg-0 alert-col-content">
                Success Alert Example Text
            </div>
        </div>
        <div class="alert-col-btn">
            <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
        </div>
        <div class="alert-col-close">
            <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
                <svg class="icon-simple-x-filled" role="img">
                    <title>Close</title>
                    <use xlink:href="icons-simple.svg#x-filled"></use>
                </svg>
            </button>
        </div>
    </div>
    </div>

<div class="alert alert-danger alert-dismissable fade show" role="alert" >
  <div class="row position-relative">
      <div class="alert-col-msg">
          <h3 class="fs-4 fw-bold">Danger Alert Example Title</h3>
          <div class="mb-2 mb-lg-0 alert-col-content">
              Danger Alert Example Text
          </div>
      </div>
      <div class="alert-col-btn">
          <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
      </div>
      <div class="alert-col-close">
          <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
              <svg class="icon-simple-x-filled" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z" fill="currentColor"/>
              </svg>
          </button>
      </div>
  </div>
</div>

    <div class="alert alert-danger alert-dismissable fade show" role="alert" >
        <div class="row position-relative">
            <div class="alert-col-msg">
                <h3 class="fs-4 fw-bold">Danger Alert Example Title</h3>
                <div class="mb-2 mb-lg-0 alert-col-content">
                    Danger Alert Example Text
                </div>
            </div>
            <div class="alert-col-btn">
                <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
            </div>
            <div class="alert-col-close">
                <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
                    <svg class="icon-simple-x-filled" role="img">
                        <title>Close</title>
                        <use xlink:href="icons-simple.svg#x-filled"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>

<div class="alert alert-warning alert-dismissable fade show" role="alert" >
  <div class="row position-relative">
      <div class="alert-col-msg">
          <h3 class="fs-4 fw-bold">Warning Alert Example Title</h3>
          <div class="mb-2 mb-lg-0 alert-col-content">
              Warning Alert Example Text
          </div>
      </div>
      <div class="alert-col-btn">
          <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
      </div>
      <div class="alert-col-close">
          <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
              <svg class="icon-simple-x-filled" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z" fill="currentColor"/>
              </svg>
          </button>
      </div>
  </div>
</div>

    <div class="alert alert-warning alert-dismissable fade show" role="alert" >
        <div class="row position-relative">
            <div class="alert-col-msg">
                <h3 class="fs-4 fw-bold">Warning Alert Example Title</h3>
                <div class="mb-2 mb-lg-0 alert-col-content">
                    Warning Alert Example Text
                </div>
            </div>
            <div class="alert-col-btn">
                <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
            </div>
            <div class="alert-col-close">
                <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
                    <svg class="icon-simple-x-filled" role="img">
                        <title>Close</title>
                        <use xlink:href="icons-simple.svg#x-filled"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>

<div class="alert alert-info alert-dismissable fade show" role="alert" >
  <div class="row position-relative">
      <div class="alert-col-msg">
          <h3 class="fs-4 fw-bold">Info Alert Example Title</h3>
          <div class="mb-2 mb-lg-0 alert-col-content">
              Info Alert Example Text
          </div>
      </div>
      <div class="alert-col-btn">
          <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
      </div>
      <div class="alert-col-close">
          <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
              <svg class="icon-simple-x-filled" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z" fill="currentColor"/>
              </svg>
          </button>
      </div>
  </div>
</div>

    <div class="alert alert-info alert-dismissable fade show" role="alert" >
        <div class="row position-relative">
            <div class="alert-col-msg">
                <h3 class="fs-4 fw-bold">Info Alert Example Title</h3>
                <div class="mb-2 mb-lg-0 alert-col-content">
                    Info Alert Example Text
                </div>
            </div>
            <div class="alert-col-btn">
                <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
            </div>
            <div class="alert-col-close">
                <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
                    <svg class="icon-simple-x-filled" role="img">
                        <title>Close</title>
                        <use xlink:href="icons-simple.svg#x-filled"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>

<div class="alert alert-light alert-dismissable fade show" role="alert" >
  <div class="row position-relative">
      <div class="alert-col-msg">
          <h3 class="fs-4 fw-bold">Light Alert Example Title</h3>
          <div class="mb-2 mb-lg-0 alert-col-content">
              Light Alert Example Text
          </div>
      </div>
      <div class="alert-col-btn">
          <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
      </div>
      <div class="alert-col-close">
          <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
              <svg class="icon-simple-x-filled" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z" fill="currentColor"/>
              </svg>
          </button>
      </div>
  </div>
</div>

    <div class="alert alert-light alert-dismissable fade show" role="alert" >
        <div class="row position-relative">
            <div class="alert-col-msg">
                <h3 class="fs-4 fw-bold">Light Alert Example Title</h3>
                <div class="mb-2 mb-lg-0 alert-col-content">
                    Light Alert Example Text
                </div>
            </div>
            <div class="alert-col-btn">
                <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
            </div>
            <div class="alert-col-close">
                <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
                    <svg class="icon-simple-x-filled" role="img">
                        <title>Close</title>
                        <use xlink:href="icons-simple.svg#x-filled"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>

<div class="alert alert-dark alert-dismissable fade show" role="alert" >
  <div class="row position-relative">
      <div class="alert-col-msg">
          <h3 class="fs-4 fw-bold">Dark Alert Example Title</h3>
          <div class="mb-2 mb-lg-0 alert-col-content">
              Dark Alert Example Text
          </div>
      </div>
      <div class="alert-col-btn">
          <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
      </div>
      <div class="alert-col-close">
          <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
              <svg class="icon-simple-x-filled" role="img" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title>Close</title>
                <path d="M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z" fill="currentColor"/>
              </svg>
          </button>
      </div>
  </div>
</div>

    <div class="alert alert-dark alert-dismissable fade show" role="alert" >
        <div class="row position-relative">
            <div class="alert-col-msg">
                <h3 class="fs-4 fw-bold">Dark Alert Example Title</h3>
                <div class="mb-2 mb-lg-0 alert-col-content">
                    Dark Alert Example Text
                </div>
            </div>
            <div class="alert-col-btn">
                <a href="~/About/Home" class="btn alert-btn flex-grow-1 mt-2 mt-lg-0 fs-8" target="_blank">Example Button</a>
            </div>
            <div class="alert-col-close">
                <button type="button" class="alert-close" data-acknowledge="~/Home/AcknowledgeNotification">
                    <svg class="icon-simple-x-filled" role="img">
                        <title>Close</title>
                        <use xlink:href="icons-simple.svg#x-filled"></use>
                    </svg>
                </button>
            </div>
        </div>
    </div>

*/






// RE-generate contextual modifier classes for colorizing the alert, because we don't use Bootstrap's default color rules for alerts

@each $state, $value in $theme-colors {
  $alert-background: $value;
  $alert-border: $value;
  $alert-color: $white;
  @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
    $alert-color: $black;
  }
  .alert-#{$state} {
    @include alert-variant($alert-background, $alert-border, $alert-color);
    .alert-btn {
        background: $alert-color;
        color: $alert-background;
        line-height: 1.5rem;
    }
    .alert-link {
        color: $alert-color;
    }
    .alert-close {
        color: $alert-color;
    }
  }
}





//additional custom styling for Istation alerts

$alert-breakpoint: "lg";

.alert-col-msg {
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up($alert-breakpoint) {
        @include make-col();
        flex-grow: 10;
        flex-shrink: 0;
    }
}
.alert-col-content :last-child {
    margin-bottom: 0;
}
.alert-col-btn {
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up($alert-breakpoint) {
        @include make-col();
        flex-grow: 2;
        flex-shrink: 0;
    }
    display: flex;
    align-items: center;
}
.alert-col-close {
    position: absolute;
    right: 1rem;
    top: 0;
    margin: unset;
    padding: unset;
    width: unset;
    
    @include media-breakpoint-up($alert-breakpoint) {
        position: static;
        @include make-col-ready();
        @include make-col();
        flex-grow: 0;
        flex-shrink: 1;
        display: flex;
    }
}
.alert-btn {
    font-weight: 700;
    display: block;
}
.alert-close {
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  opacity: .5;
  &:hover {
      opacity: 1;
  }
}