@use 'mixins' as *;

.bg--pink {
  background-color: #f6ecf5;

  &.mobile {
    background-color: transparent;

    @include respond-to(md) {
      background-color: #f6ecf5;
    }
  }

  &-10 {
    background-color: rgba(170, 60, 154, 0.1) !important;
  }

  &-30 {
    background-color: rgba(#aa3c9a, 0.3) !important;
  }
}

.bg--orange {
  background-color: #ffefe6;

  &.mobile {
    background-color: transparent;

    @include respond-to(md) {
      background-color: #ffefe6;
    }
  }

  &-10 {
    background-color: #ffd2b9 !important;
  }

  &-30 {
    background-color: rgba(#ff5e03, 0.3) !important;
  }
}

.bg--blue {
  background-color: #ebedfc;

  &.mobile {
    background-color: transparent;

    @include respond-to(md) {
      background-color: #ebedfc;
    }
  }

  &-10 {
    background-color: #ebedfc !important;
  }

  &-30 {
    background-color: rgba(#354add, 0.3) !important;
  }
}
