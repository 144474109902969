.form-control-search {

    &:focus {
        border-color: $input-btn-search-focus-color;
    }

    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        display: inline-block;
        margin-right: -.25rem;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6968 12L17.0916 8.6064C17.2031 8.49491 17.2915 8.36255 17.3519 8.21688C17.4122 8.0712 17.4433 7.91507 17.4433 7.7574C17.4433 7.59973 17.4122 7.4436 17.3519 7.29793C17.2915 7.15225 17.2031 7.01989 17.0916 6.9084C16.9801 6.79691 16.8477 6.70847 16.7021 6.64813C16.5564 6.58779 16.4003 6.55673 16.2426 6.55673C16.0849 6.55673 15.9288 6.58779 15.7831 6.64813C15.6375 6.70847 15.5051 6.79691 15.3936 6.9084L12 10.3032L8.6064 6.9084C8.38123 6.68323 8.07584 6.55673 7.7574 6.55673C7.43896 6.55673 7.13357 6.68323 6.9084 6.9084C6.68323 7.13357 6.55673 7.43896 6.55673 7.7574C6.55673 7.91507 6.58779 8.0712 6.64813 8.21688C6.70847 8.36255 6.79691 8.49491 6.9084 8.6064L10.3032 12L6.9084 15.3936C6.68323 15.6188 6.55673 15.9242 6.55673 16.2426C6.55673 16.561 6.68323 16.8664 6.9084 17.0916C7.13357 17.3168 7.43896 17.4433 7.7574 17.4433C8.07584 17.4433 8.38123 17.3168 8.6064 17.0916L12 13.6968L15.3936 17.0916C15.6188 17.3168 15.9242 17.4433 16.2426 17.4433C16.561 17.4433 16.8664 17.3168 17.0916 17.0916C17.3168 16.8664 17.4433 16.561 17.4433 16.2426C17.4433 15.9242 17.3168 15.6188 17.0916 15.3936L13.6968 12ZM12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24Z' fill='%23"+$input-btn-search-close-color-hex+"'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 1.25rem;
        height: 1.25rem;
        background-size: contain;
        pointer-events: none;
        opacity: 0;
    }

    &:focus::-webkit-search-cancel-button {
        pointer-events: all;
        cursor: pointer;
        opacity: 1;
    }

}