//The offcanvas-exds modifier class is meant to be placed on the default Bootstrap .offcanvas element. 
//The modifier class changes the behavior of the responsive sizing to match the screen widths that are demonstrated in the mockups:
//on mobile and tablet, or screens less than 992px i.e. the Bootstrap LG breakpoint, the offcanvas is full width; 
//at greater than or equal to 992px, the offcanvas is 600px;
//removing offcanvas-exds restores Bootstrap's default behavior (the offcanvas is the full width of the screen until tbhe screen width is > 600px, at which point the offcanvas is 600px)

$offcanvas-exds-footer-height: 5.9375rem; //95px

.offcanvas-exds {
    width: 100%;
}
@include media-breakpoint-up(lg) {
    .offcanvas-exds {
        width: $offcanvas-horizontal-width;
    }
}

//Give an absolutely position footer
.offcanvas-exds-has-footer {
    .offcanvas-exds-body {
        padding-bottom: $offcanvas-exds-footer-height;
        position: relative;
        overflow-y: scroll; //keep the scrollbar on all the time so we don't get an alignment problem with the footer border
    }
}
//this footer has no border
.offcanvas-exds-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: $offcanvas-padding-y $offcanvas-padding-x;
    text-align: center;
    background: $offcanvas-bg-color;
    height: $offcanvas-exds-footer-height;
}
//this footer has a "border" on top that matches the border on a kendo grid placed in the offcanvas body element
.offcanvas-exds-grid-footer {
    @extend .offcanvas-exds-footer;
    //border-top: $grid-border-width solid $grid-border-color; //this works
    //box-shadow: 0px -4px 4px rgb(0 0 0 / 8%); //this doesn't work - places shadow around the entire box
    //instead of actually placing a border and a shadow on the top of this footer area, we can fake it with a background gradient
    padding: calc($offcanvas-padding-y + 4px) 0 $offcanvas-padding-y 0; //the y offset corrects for the border being in the background
    margin: 0 ($offcanvas-padding-x + 1rem) 0 $offcanvas-padding-x; //the x offset places the 'border' in the correct place relative to the window and scrollbar
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 94%, rgba(237,239,240,1) 94%, rgba(237,239,240,1) 96%, rgba(0,0,0,0.05) 96%, rgba(255,255,255,0) 100%);
}

//Classes that format the heading and subheading text to match mockups
.offcanvas-exds-heading {
    font-size: 1.3125rem;
    font-weight: 500;
}

.offcanvas-exds-subheading {
    font-size: .875rem;
    font-weight: normal;
    color: $grayblue-500;
    line-height: 1rem;
}

//Format the close button to match mockups
.offcanvas-exds-close {
    border: 1px solid $grayblue-500;
    border-radius: $border-radius-pill;
    font-size: 1.125rem;
    font-weight: 500;
    color: $grayblue-500;
    padding: .5rem 1.5rem;
    &:hover {
        color: $grayblue-500;
        border-color: $grayblue-500;
    }
}


//MANAGER DRILLDOWN
$offcanvas-drilldown-offset: 2.5rem;
$offcanvs-drilldown-shadow: ();

#DistrictOffcanvas {
    z-index: $zindex-offcanvas-dist;
}
  
#CampusOffcanvas {
    z-index: $zindex-offcanvas-campus;
}

#ClassroomOffcanvas {
    z-index: $zindex-offcanvas-clsrm;
}

.offcanvas-back-btn {
    color: $link-color;
    font-size: .875rem;
}




//LOADING / PLACEHOLDER / SHIMMER
.offcanvas-exds-placeholder ul {
    list-style: none;
}
