//// SPACING

/*
@styleguide

@title Utility: Spacing

We use customized versions of Bootstrap's <a href="https://getbootstrap.com/docs/5.1/utilities/spacing/" target="_blank">spacing utility classes</a> to better accomodate designs built on a four-pixel grid. The main difference is in the greater range of options for <span class="text-danger">sizing</span>.

Like in Bootstrap, the classes are named using the format <span class="text-primary">{property}</span><span class="text-success">{sides}</span>-<span class="text-danger">{size}</span> for xs and <span class="text-primary">{property}</span><span class="text-success">{sides}</span>-<span class="text-warning">{breakpoint}</span>-<span class="text-danger">{size}</span> for sm, md, lg, xl, and xxl.

The custom <span class="text-danger">{size}</span> options are as follows when $spacer is set to 1rem (16px):

- 0 - for classes that eliminate the margin or padding by setting it to 0
- 1 - for classes that set the margin or padding to $spacer * .25rem (4px*)
- 2 - for classes that set the margin or padding to $spacer * .5rem (8px*)
- 3 - for classes that set the margin or padding to $spacer * .75rem (12px*)
- 4 - for classes that set the margin or padding to $spacer * 1rem (16px*)
- 5 - for classes that set the margin or padding to $spacer * 1.25rem (20px*)
- 6 - for classes that set the margin or padding to $spacer * 1.5rem (24px*)
- 7 - for classes that set the margin or padding to $spacer * 1.75rem (28px*)
- 8 - for classes that set the margin or padding to $spacer * 2rem (32px*)
- 9 - for classes that set the margin or padding to $spacer * 2.25rem (36px*)
- 10 - for classes that set the margin or padding to $spacer * 2.5rem (40px*)
- 11 - for classes that set the margin or padding to $spacer * 2.75rem (44px*)
- 12 - for classes that set the margin or padding to $spacer * 3rem (48px*)
- 13 - for classes that set the margin or padding to $spacer * 3.25rem (52px*)
- 14 - for classes that set the margin or padding to $spacer * 3.5rem (56px*)
- 15 - for classes that set the margin or padding to $spacer * 3.75rem (60px*)
- 16 - for classes that set the margin or padding to $spacer * 4rem (64px*)
- auto - for classes that set the margin to auto

Like in Bootstrap 5, <span class="text-primary">{property}</span> can be one of:

- m - for classes that set margin
- p - for classes that set padding

Like in Bootstrap 5, <span class="text-success">{sides}</span> can be one of:

- t - for classes that set margin-top or padding-top
- b - for classes that set margin-bottom or padding-bottom
- s - (start) for classes that set margin-left or padding-left in LTR, margin-right or padding-right in RTL
- e - (end) for classes that set margin-right or padding-right in LTR, margin-left or padding-left in RTL
- x - for classes that set both *-left and *-right
- y - for classes that set both *-top and *-bottom
- blank - for classes that set a margin or padding on all 4 sides of the element 

Like in Bootstrap 5, <span class="text-warning">{breakpoint}</span> can be one of the <a href="https://getbootstrap.com/docs/5.1/layout/breakpoints/" target="_blank">Bootstrap breakpoints</a>:

- n/a - for classes that apply to all breakpoints
- sm - for classes that apply to the small breakpoint (576px) and up
- md - for classes that apply to the medium breakpoint (768px) and up
- lg - for classes that apply to the large breakpoint (992px) and up
- xl - for classes that apply to the extra large breakpoint (1200px) and up
- xxl - for classes that apply to the extra extra large breakpoint (1400px) and up

Spacing utilities that apply to all breakpoints, from xs to xxl, have no {breakpoint} abbreviation in them. This is because those classes are applied from min-width: 0 and up, and thus are not bound by a media query. The remaining breakpoints, however, do include a breakpoint abbreviation.

*/




//// TYPOGRAPHY

/*
@styleguide

@title Utility: Font size classes

We use customized versions of Bootstrap's <a href="https://getbootstrap.com/docs/5.3/utilities/text/#font-size" target="_blank">font sizing utility classes</a>.

Like in Bootstrap, the classes are named using the format .fs-{number}, where number is one of:

- 1 - $h1-font-size / 2.5rem / 40px
- 2 -  $h2-font-size / 2rem / 32px
- 3 - $h3-font-size / 1.75rem / 28px
- 4 - $h4-font-size /  1.5rem / 24px
- 5 - $h5-font-size /  1.25rem / 20px
- 6 - $font-size-md / 1.125rem / 18px
- 7 - $h6-font-size / 1 rem / 16px
- 8 - $font-size-sm / 0.875 rem / 14px
- 9 - $font-size-xs / .75rem / 12px

<p class="fs-1">.fs-1</p>

	<p class="fs-1">.fs-1</p>

<p class="fs-2">.fs-2</p>

	<p class="fs-2">.fs-2</p>

<p class="fs-3">.fs-3</p>

	<p class="fs-3">.fs-3</p>

<p class="fs-4">.fs-4</p>

	<p class="fs-4">.fs-4</p>

<p class="fs-5">.fs-5</p>

	<p class="fs-5">.fs-5</p>

<p class="fs-6">.fs-6</p>

	<p class="fs-6">.fs-6</p>

<p class="fs-7">.fs-7</p>

	<p class="fs-7">.fs-7</p>

<p class="fs-8">.fs-8</p>

	<p class="fs-8">.fs-8</p>

<p class="fs-9">.fs-9</p>

	<p class="fs-9">.fs-9</p>

*/




/*
@styleguide

@title Utility: Font color classes

These are custom utility classes in addition to the Bootstrap-enabled <a href="https://getbootstrap.com/docs/5.3/utilities/colors/" target="_blank">color utility classes</a>.

<p class="text-warm-contrast bg-warm">.text-warm-contrast</p>

	<p class="text-warm-contrast bg-warm">.text-warm-contrast</p>

<p class="text-warning-contrast bg-warning">.text-warning-contrast</p>

	<p class="text-warning-contrast bg-warning">.text-warning-contrast</p>

*/

.text-warm-contrast {
	color: $color-contrast-warm !important;
}
.text-warning-contrast {
	color: $color-contrast-warning !important;
}



/*
@styleguide

@title Utility: Font weight classes

We use a different set of font weight utility classes that work the same as Bootstrap's <a href="https://getbootstrap.com/docs/5.3/utilities/text/#font-weight-and-italics" target="_blank">font weight utility classes</a>.

- .fw-lighter gives a font weight lighter than the parent element's font weight (if a lighter weight font set is available)
- .fw-normal gives font weight 400
- .fw-medium gives font weight 500**
- .fw-bold gives font-weight 700
- .fw-bolder gives a font weight bolder than the parent element's font weight (if a bolder weight font set is available)

More font weight utility classes cannot be added or supported without adding additional font resources (Roboto Regular, Roboto Medium, and Roboto Bold are the only three font sets currently loaded).

**Before Bootstrap had font weight utilities with these specific names, this codebase supported a .font-weight-500 class (this is now dead) and a .fw-md class (this name has been deprecated in favor of .fw-medium). The .fw-medium class has the same effect as both of these older utilities and should be used instead.


<p class="fw-normal">.fw-normal</p>

	<p class="fw-normal">.fw-normal</p>

<p class="fw-medium">.fw-medium</p>

	<p class="fw-medium">.fw-medium</p>

<p class="fw-bold">.fw-bold</p>

	<p class="fw-bold">.fw-bold</p>

*/

// Font weight
// Bootstrap's latest version has font weight utilities; some custom utility classes w/ different names are and used to be defined here
$utilities: map-merge(
  $utilities,
  (
    "font-weight": map-merge(
      map-get($utilities, "font-weight"),
      (
        values: map-merge(
          map-get(map-get($utilities, "font-weight"), "values"),
          (
			md: $font-weight-md,
		    medium: $font-weight-medium
		  )
        ),
      ),
    ),
  )
);

// .FONT-WEIGHT-500 IS DEAD
// .fw-md is also deprecated and should be removed at some point
// Use .fw-medium to get the same effect as .font-weight-500 and .fw-md
// .font-weight-500 {
//     font-weight: 500;
// }









/*
@styleguide

@title Utility: Border radius classes

- .rounded and .rounded-2 give a border radius of 1rem / 16px
- .rounded-0 gives a border radius of 0
- .rounded-1 gives a border radius of 0.25rem / 4px
- .rounded-3 gives a border radius of 1.75rem / 28px
- .rounded-circle gives a border radius of 50% 
- .rounded-pill gives a border radius of 50rem
- .rounded-top gives the top two corners of the element border radii of 1rem / 16px
- .rounded-end gives the ending(right in LTR) two corners of the element border radii of 1rem / 16px
- .rounded-bottom gives the bottom two corners of the element border radii of 1rem / 16px
- .rounded-start gives the starting(left in LTR) two corners of the element border radii of 1rem / 16px

*/






/*
@styleguide

@title Utility: Internal indicator

.has-internal-indicator creates a circle with purple400 as the color and is 6px by 6px

<ul><li>For internal eyes only<span class="has-internal-indicator"></span></li></ul>

	<ul><li>For internal eyes only<span class="has-internal-indicator"></span></li></ul>
*/



.has-internal-indicator {
	height: 6px;
	width: 6px;
	background-color: $purple-400;
	border-radius: 50%;
	display: inline-block;
	margin-left: 4px;
	vertical-align: middle !important;
}