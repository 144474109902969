.exds-nav-tabs {
  .nav-link {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    margin-right: 1.5rem;
    color: $grayblue-500;
  }

  .badge {
    font-size: 14px;
    font-weight: 700;
    color: $grayblue-500;
    background: $grayblue-200;
    padding: 0 .375rem;
    line-height: 1rem;
  }

  .active {
    .badge {
      color: $white;
      background: $nav-tabs-link-active-color;
    }
  }

}