:root {
    
    //See Bootstrap 5's original root partial for more on this approach
    // We needed more CSS variables to use with Kendo

    // Performance Scale Colors
    //
    // Generate palettes for Istation's performance scales

    @each $color, $value in $perfscale1-solids-stripes {
        --#{$variable-prefix}perfscale1-#{$color}: #{$value};
    }

    @each $color, $value in $perfscale2-solids-stripes {
        --#{$variable-prefix}perfscale2-#{$color}: #{$value};
    }
    
}