
//LEVELS
$perfscale1-lvl1-solid: $red-500;
$perfscale1-lvl1-stripe1: $red-100;
$perfscale1-lvl1-stripe2: $red-400;
$perfscale1-lvl1-contrast: $white;
$perfscale1-lvl2-solid: $yellow-500;
$perfscale1-lvl2-stripe1: $yellow-500;
$perfscale1-lvl2-stripe2: $yellow-700;
$perfscale1-lvl2-contrast: $grayblue-500;
$perfscale1-lvl3-solid: $green-300;
$perfscale1-lvl3-stripe1: $green-300;
$perfscale1-lvl3-stripe2: $green-600;
$perfscale1-lvl3-contrast: $grayblue-500;
$perfscale1-lvl4-solid: $green-500;
$perfscale1-lvl4-stripe1: $green-500;
$perfscale1-lvl4-stripe2: $green-700;
$perfscale1-lvl4-contrast: $white;
$perfscale1-lvl5-solid: $green-700;
$perfscale1-lvl5-stripe1: $green-700;
$perfscale1-lvl5-stripe2: $white;
$perfscale1-lvl5-contrast: $white;

$perfscale1-solids: (
    "lvl1-solid": $perfscale1-lvl1-solid,
    "lvl2-solid": $perfscale1-lvl2-solid,
    "lvl3-solid": $perfscale1-lvl3-solid,
    "lvl4-solid": $perfscale1-lvl4-solid,
    "lvl5-solid": $perfscale1-lvl5-solid
);

$perfscale1-stripes: (
    "lvl1-stripe1": $perfscale1-lvl1-stripe1,
    "lvl1-stripe2": $perfscale1-lvl1-stripe2,
    "lvl2-stripe1": $perfscale1-lvl2-stripe1,
    "lvl2-stripe2": $perfscale1-lvl2-stripe2,
    "lvl3-stripe1": $perfscale1-lvl3-stripe1,
    "lvl3-stripe2": $perfscale1-lvl3-stripe2,
    "lvl4-stripe1": $perfscale1-lvl4-stripe1,
    "lvl4-stripe2": $perfscale1-lvl4-stripe2,
    "lvl5-stripe1": $perfscale1-lvl5-stripe1,
    "lvl5-stripe2": $perfscale1-lvl5-stripe2
);

$perfscale1-solids-contrast: (
    "lvl1-contrast": $perfscale1-lvl1-contrast,
    "lvl2-contrast": $perfscale1-lvl2-contrast,
    "lvl3-contrast": $perfscale1-lvl3-contrast,
    "lvl4-contrast": $perfscale1-lvl4-contrast,
    "lvl5-contrast": $perfscale1-lvl5-contrast
);

$perfscale1-solids-stripes: map-merge($perfscale1-solids,$perfscale1-stripes);
$perfscale1: map-merge($perfscale1-solids-stripes,$perfscale1-solids-contrast);

//TIERS
//TIER 1 LOOKS LIKE LEVEL 4
//TIER 2 LOOKS LIKE LEVEL 2
//TIER 3 LOOKS LIKE LEVEL 1
$perfscale2-lvl1-solid: $green-500;
$perfscale2-lvl1-stripe1: $green-500;
$perfscale2-lvl1-stripe2: $green-700;
$perfscale2-lvl1-contrast: $white;
$perfscale2-lvl2-solid: $yellow-500;
$perfscale2-lvl2-stripe1: $yellow-500;
$perfscale2-lvl2-stripe2: $yellow-700;
$perfscale2-lvl2-contrast: $grayblue-500;
$perfscale2-lvl3-solid: $red-500;
$perfscale2-lvl3-stripe1: $red-100;
$perfscale2-lvl3-stripe2: $red-400;
$perfscale2-lvl3-contrast: $white;

$perfscale2-solids: (
    "lvl1-solid": $perfscale2-lvl1-solid,
    "lvl2-solid": $perfscale2-lvl2-solid,
    "lvl3-solid": $perfscale2-lvl3-solid
);

$perfscale2-stripes: (
    "lvl1-stripe1": $perfscale2-lvl1-stripe1,
    "lvl1-stripe2": $perfscale2-lvl1-stripe2,
    "lvl2-stripe1": $perfscale2-lvl2-stripe1,
    "lvl2-stripe2": $perfscale2-lvl2-stripe2,
    "lvl3-stripe1": $perfscale2-lvl3-stripe1,
    "lvl3-stripe2": $perfscale2-lvl3-stripe2
);

$perfscale2-solids-contrast: (
    "lvl1-contrast": $perfscale2-lvl1-contrast,
    "lvl2-contrast": $perfscale2-lvl2-contrast,
    "lvl3-contrast": $perfscale2-lvl3-contrast
);

$perfscale2-solids-stripes: map-merge($perfscale2-solids,$perfscale2-stripes);
$perfscale2: map-merge($perfscale2-solids-stripes,$perfscale2-solids-contrast);