/**
 * ============================================================================
 * Configuration
 * ----------------------------------------------------------------------------
 * This includes all functions, mixins, variables, etc. in the proper order.
 * The theme file will prime the variable environment with SCSS variables,
 * functions, and mixins used through the application and allows for
 * injection of custom overrides for Bootstrap built-ins
 * ============================================================================
 */
@import "theme";

/**
 * ============================================================================
 * Bootstrap Layout
 * ============================================================================
 */
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

/**
 * ============================================================================
 *  Bootstrap Components
 * ----------------------------------------------------------------------------
 *
 *  This list is customizable. Turn off what you don't need.
 *
 *  Also because this project is in its infancy these haven't been "vetted" to 
 *  be sure they match up with the mockups yet!
 *
 * ============================================================================
 */
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/helpers";

//
// ... and Bootstrap utilities API ....
//
@import "bootstrap/scss/utilities/api";


/**
 * ============================================================================
 *  Overrides
 * ----------------------------------------------------------------------------
 *  In cases where a Bootstrap, Kendo or otherwise outside CSS component exists 
 *  but we have a need to modify it or expand it
 * ============================================================================
 */
@import "reboot";
@import "buttons";
@import "card";
@import "caret";
@import "tooltip";
@import "alert";
//@import "kendo-grid";
//@import "dt-grid";
@import "modal";
@import "navbar";
@import "offcanvas";
@import "nav-tabs";
@import "badge";
@import "dropdowns";
@import "progress-bars";
@import "form-control";

/**
 * ============================================================================
 *  Custom Components & SCSS
 * ============================================================================
 */
@import "icons-simple";
@import "icons-perfscale";
@import "typography";
@import "root";
@import "panels";
@import "survey";