@use 'variables';

.headline-1 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: variables.$mainText;
}

.headline-2 {
  font-size: 1.375rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: variables.$mainText;
}

.headline-3 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: variables.$mainText;
}

.text {
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  color: variables.$mainText;
}

.text-2 {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 600;
  color: variables.$mainText;
}

.text-3 {
  font-size: 1rem;
  line-height: 1.25rem;
  color: variables.$mainText;
  font-weight: 600;
}

.list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  &--horizontal {
    flex-direction: row;
    gap: 0.25rem;
  }

  &--vertical {
    flex-direction: column;
    gap: 0.75rem;

    list-style-image: url('/assets/icons/list-point.svg');
  }
}
