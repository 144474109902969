@use 'variables' as *;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin resolution($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin custom-resolution($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin from-resolution($from) {
  @media (max-width: $from) {
    @content;
  }
}

@mixin def-font($size: 16px, $weight: 400) {
  @if $size == null {
    $size: 16px;
  }

  font-variant-numeric: lining-nums proportional-nums;
  color: var(--jit-font-color);
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: normal;
  @content;
}

@mixin def-border($size: 1px, $color: var(--jit-font-color), $radius: var(--jit-border-radius)) {
  @if $size == null {
    $size: 1px;
  }

  @if $color == null {
    $color: var(--jit-font-color);
  }

  border-radius: $radius;
  border: $size solid $color;
  @content;
}

@mixin flex-row($justifyContent: flex-start, $alignItems: center, $gap: 0) {
  @if $justifyContent == null {
    $justifyContent: flex-start;
  }

  @if $alignItems == null {
    $alignItems: center;
  }

  display: flex;
  flex-direction: row;
  justify-content: $justifyContent;
  align-items: $alignItems;
  gap: $gap;
  @content;
}

@mixin flex-col($justifyContent: flex-start, $alignItems: center, $gap: 0) {
  @if $justifyContent == null {
    $justifyContent: flex-start;
  }

  @if $alignItems == null {
    $alignItems: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: $justifyContent;
  align-items: $alignItems;
  gap: $gap;
  @content;
}
