/*
@styleguide

@title Component: Cards

We use a customized version of Bootstrap's <a href="https://getbootstrap.com/docs/5.1/components/card/" target="_blank">cards</a> that has no border("outline") or dropshadow("shadow") on the default state.

If a card with a border and dropshadow is needed, it can be created by adding utility classes.

<div class="bg-dark p-3">
    <div class="card">
        <div class="card-body">
            <div class="card-content">
                <p class="mb-0"><strong class="fs-9">Default card (shown here on a dark background) has no border or dropshadow</strong></p>
            </div>
        </div>
    </div>
</div>

    <div class="bg-dark">
        <div class="card">
            <div class="card-body">
                <div class="card-content">
                    <p class="mb-0"><strong class="fs-9">Default card (shown here on a dark background) has no border or dropshadow</strong></p>
                </div>
            </div>
        </div>
    </div>

<div class="card card-outline">
    <div class="card-body">
        <div class="card-content">
            <p class="mb-0"><strong class="fs-9">A border can be added with .card-outline</strong></p>
        </div>
    </div>
</div>

    <div class="card card-outline">
        <div class="card-body">
            <div class="card-content">
                <p class="mb-0"><strong class="fs-9">A border can be added with .card-outline</strong></p>
            </div>
        </div>
    </div>

<div class="card card-shadow">
    <div class="card-body">
        <div class="card-content">
            <p class="mb-0"><strong class="fs-9">A dropshadow can be added with .card-shadow</strong></p>
        </div>
    </div>
</div>

    <div class="card card-shadow">
        <div class="card-body">
            <div class="card-content">
                <p class="mb-0"><strong class="fs-9">A dropshadow can be added with .card-shadow</strong></p>
            </div>
        </div>
    </div>

<div class="card card-outline card-shadow">
    <div class="card-body">
        <div class="card-content">
            <p class="mb-0"><strong class="fs-9">Both a border and dropshadow can be added with both modifier classes</strong></p>
        </div>
    </div>
</div>

    <div class="card card-outline card-shadow">
        <div class="card-body">
            <div class="card-content">
                <p class="mb-0"><strong class="fs-9">Both a border and dropshadow can be added with both modifier classes</strong></p>
            </div>
        </div>
    </div>

*/




//Bootstrap's default card has a border and no drop-shadow
//The EXDS default card has neither a border or a drop-shadow
//So let's make the Bootstrap default match the EXDS default, and add a :not exception for a .card-outline class that will keep the border to give the bordered variant from the mockups
.card:not(.card-outline) {
    border: none;
}

//Another variant from the mockups we need to add
.card-shadow {
    box-shadow: $card-box-shadow; //we don't use Bootstrap's mixin here because it looks at the global value of $enable-shadows to determine whether to show the shadows or not. And this turns on some shadows we don't want. So we're overriding it just for cards.
}