.jit-table {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;

  thead {
    tr {
      border-bottom: 1px solid #DDE1E9;

      th {
        padding: 10px 8px;
        color: #1A396A;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        jit-th-sort {
          margin-left: 8px;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #DDE1E9;

      &:last-child {
        border-bottom: 0;
      }

      td {
        padding: 16px 8px;
        color: #1A396A;
        font-variant-numeric: lining-nums proportional-nums;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}