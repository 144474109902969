/*ICON-PERFSCALE icon set*/
/*color classes must be combined to generate the icon*/
/*re: <div class="icon-perfscale1-lvl4-solid icon-perfscale1-lvl4-contrast-txt">4</div>*/
/*Perfscale1 is Levels, Perfscale2 is Tiers*/
[class*=icon-perfscale] {
    display: inline-block;
    border: 1px solid $grayblue-500;
    border-radius: $border-radius-pill;
    background: transparent;
    color: $grayblue-500;
    font-weight: 700;
    font-size: .875rem;
    text-align: center;
    line-height: 1.375rem;
    @extend .icon-sz-24s; //we have to have a default size... it's 24 by 24px
}

@each $label, $color in $perfscale1-solids {
    .icon-perfscale1-#{$label} {
        background-color: $color;
    }
}

@each $label, $color in $perfscale2-solids {
    .icon-perfscale2-#{$label} {
        background-color: $color;
    }
}

@each $label, $color in $perfscale1-solids-contrast {
    .icon-perfscale1-#{$label}-txt {
        color: $color;
    }
}

@each $label, $color in $perfscale2-solids-contrast {
    .icon-perfscale2-#{$label}-txt {
        color: $color;
    }
}

//the solid color on these number icons varies a bit from the other perf scale color implementations
//so these limited colors are customized here

//level 1 and tier 3 icons are a lighter red than the red of the bar chart
.icon-perfscale1-lvl1-solid,
.icon-perfscale2-lvl3-solid
{
    background-color: $red-400;
}

//level 5 icon is a lighter green than the green of the bar chart
.icon-perfscale1-lvl5-solid
{
    background-color: $green-600;
}