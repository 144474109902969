#productDropdown .dropdown-menu.show li:last-child hr.dropdown-divider{
    display: none;
}

#global-search-form-w .dropdown-menu.show li:last-of-type {
    display: none;
}

#campusDropdownMenu {
    max-height: 400px;
    min-width: 350px;
    overflow-y: auto;
}
#campusDropdownMenu.dropdown-menu.show li:last-of-type {
    display: none;
}
#campusDropdownMenu span {
    color: $grayblue-500;
}