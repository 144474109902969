$mainColor: #1a396a;
$greyColor: #d9d9d9;
$secondaryColor: #1e384a;
$mainBackground: radial-gradient(
    50% 50% at 50% 50%,
    rgba(15, 152, 252, 0) 34.37%,
    rgba(3, 196, 222, 0.2) 100%
  ),
  linear-gradient(0deg, #b2dcfb, #b2dcfb);

//--- Text color
$white: #ffffff;
$mainText: #1a396a;
$secondaryText: #627187;
//-- Button Color
$pink: #aa3c9a;
$orange: #ff5e03;
$blue: #354add;
$grey: #dde1e9;
$grey-light: #d8d8d8;
$grey-dark: #8b8b8b;
$dark-blue: #1a396a;
$light-pink: #f4c8ee;
$light-orange: #ffd2b9;
$light-blue: #c3cafc;
$red: #ef123a;
$green: #55c365;

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
