/*DATA PANEL*/
/*Similar to a card, but has some other responsive behaviors. 
I.e. the "Assessment Dashboard" and the "Usage Dashboard" (as defined in Confluence by Product) on the home screen*/

.data-panel {
    background-color: $card-bg;
    @include border-radius($card-border-radius);
    box-shadow: $card-box-shadow; //we don't use Bootstrap's mixin here because it looks at the global value of $enable-shadows to determine whether to show the shadows or not. And this turns on some shadows we don't want. So we're overriding it just for cards.
    padding: $card-spacer-y $card-spacer-x;
}

//click affordance
.data-panel-pointer {
    cursor: pointer;   
}

/*DATA PANEL INFO*/
//special modifications for the usage panel that has a special header
.data-panel-usage {
    .data-panel-info {
        margin-left: -0.25rem;
        margin-top: -0.25rem;
    }
}

/*DATA PANEL DATA LIST*/
/*The numbers that together comprise the Assessment Completion Summary and Usage Breakdown Legend on each data panel*/
.data-panel-data-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: .875rem;

    //the next three declarations are the default settings for the data panel data list
    //they apply to usage where the items are displayed horizontally, but not always to assessments
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
}
//special modifications for the assessments data list
.data-panel-assessments {
    .data-panel-data-list {
        display: block; //make the list horizontal
        li + li {
            margin-top: 1rem; //space out the list items
        }
    }
}

/*DATA PANEL DATA NUMBER*/
/*The large, bolded number portion of the Data Panel Data List*/
.data-panel-data-number {
    display: block;
    font-weight: 700;
    font-size: 1.3125rem;
    line-height: 1.5rem;
}
//special modifications for the usage number
.data-panel-usage {
    .data-panel-data-number {
        @extend .data-panel-data-number;
        font-size: 18px;
    }
}

/*DATA PANEL DATA CHART*/
.data-panel-assessments {
    .data-panel-data-chart {
        width: 100%;
        height: 100%;
        min-height: 150px;
        position: relative;
    }
    .data-panel-data-chart-placeholder {
        width: 100%;
        height: 100%;
        background-color: currentColor;
        cursor: wait;
        opacity: .5;
        vertical-align: middle;
        -webkit-animation: placeholder-glow 2s ease-in-out infinite;
        animation: placeholder-glow 2s ease-in-out infinite;
    }
}


/*DATA PANEL DATA LEGEND*/

//special modifications for the assessments kendo chart legend
.data-panel-assessments {
    .data-panel-legend {
        display: flex;
        font-size: .875rem;
        justify-content: space-evenly;
    }
    .data-panel-legend-item {
        text-align: center;
    }
    .data-panel-legend-marker {
        display: inline-block;
        width: .5rem;
        height: .5rem;
        border-radius: 100px;
    }
}

.data-panel-data-list-usage {
    text-transform: capitalize;
}
.data-panel-data-legend {
    border: 1px solid $grayblue-600;
    border-radius: 50%;
    width: 8px;
    height: 8px;

    &-under {
        background-color: $red-500;
    }
    &-active {
        background-color: $blue-500;
    }
    &-metgoal {
        background-color: $green-500;
    }
    &-over {
        background-color: $green-600;
    }
}
.data-panel-data-legend-loading {
    background-color: transparent !important;
}

/*DATA PANEL PRELOAD*/

/*In the event of a high server load, an alternate UI state appears and prompts the user to load data. Some informational text in this UI state is placed in a paragraph element with this class. */
.data-panel-preload-msg {
	line-height: 1.125rem; // 18px
	max-width: 9.6875rem; // 155px 
}

/*RESPONSIVE*/
@include media-breakpoint-up(md) {
    .data-panel {
        height: 100%;
    }
}

@include media-breakpoint-up(lg) {
    .data-panel {
        height: auto;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
    }
    .data-panel-assessments {
        .data-panel-data-list {
            display: flex;
            flex-flow: row nowrap;
            justify-content: start;
            li + li {
                margin-top: 0;
                margin-left: 1.5rem;
            }
            li {
                flex-grow: 1; //makes THE SHINING "loading" bars behave... also alters the display of the real content from the mockup content though
            }
        }
        .data-panel-data-chart {
            margin-top: 1.75rem;
        }
    }
   .data-panel-usage {
        margin-top: 1.5rem;
        border-top: 1px solid $blue-400;
        padding-top: 1.5rem;
    }
}