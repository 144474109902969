/*
@styleguide

@title Component: Badges

EXDS mockups refer to these as lozenges but Bootstrap calls them badges. Because some of these already exist in Bootstrap, we're piggybacking on the Bootstrap 'badge' convenrtion in the CSS code.

The EXDS variants (.exds-badge-status) exist alongside the Bootstrap default(.badge .bg-themecolor) variants to give all of the possible color combinations represented in the design system mockups. Differences between the .exds-badge types and the .badge .bg-themecolor types are the color combinations and the need to specify a separate text color via utility class for the .badge variants if the desired text color is not black or white (for an example, see the warm and warning badges below).

<span class="text-uppercase exds-badge-light">exds-badge-light</span> <span class="text-uppercase exds-badge-dark">exds-badge-dark</span> 

	<span class="text-uppercase exds-badge-light">exds-badge-light</span> <span class="text-uppercase exds-badge-dark">exds-badge-dark</span> 

<span class="text-uppercase exds-badge-primary">exds-badge-primary</span> <span class="text-uppercase badge bg-primary">badge bg-primary</span> 

	<span class="text-uppercase exds-badge-primary">exds-badge-primary</span> <span class="text-uppercase badge bg-primary">badge bg-primary</span> 

<span class="text-uppercase exds-badge-danger">exds-badge-danger</span> <span class="text-uppercase badge bg-danger">badge bg-danger</span> 

	<span class="text-uppercase exds-badge-danger">exds-badge-danger</span> <span class="text-uppercase badge bg-danger">badge bg-danger</span> 

<span class="text-uppercase exds-badge-success">exds-badge-success</span> <span class="text-uppercase badge bg-success">badge bg-success</span> 

	<span class="text-uppercase exds-badge-success">exds-badge-success</span> <span class="text-uppercase badge bg-success">badge bg-success</span> 

<span class="text-uppercase exds-badge-warm">exds-badge-warm</span> <span class="text-uppercase badge bg-warm text-warm-contrast">badge bg-warm text-warm-contrast</span> 

	<span class="text-uppercase exds-badge-warm">exds-badge-warm</span> <span class="text-uppercase badge bg-warm text-warm-contrast">badge bg-warm text-warm-contrast</span> 

<span class="text-uppercase exds-badge-info">exds-badge-info</span> <span class="text-uppercase badge bg-info">badge bg-info</span> 

	<span class="text-uppercase exds-badge-info">exds-badge-info</span> <span class="text-uppercase badge bg-info">badge bg-info</span> 

<span class="text-uppercase exds-badge-warning">exds-badge-warning</span> <span class="text-uppercase badge bg-warning text-warning-contrast">badge bg-warning text-warning-contrast</span> 

	<span class="text-uppercase exds-badge-warning">exds-badge-warning</span> <span class="text-uppercase badge bg-warning text-warning-contrast">badge bg-warning text-warning-contrast</span> 

*/




.exds-badge-light {
	@extend .badge;
	color: $grayblue-500;
	background: $gray-200;
}

.exds-badge-dark {
	@extend .badge;
	color: $gray-100;
	background: $grayblue-500;
}

.exds-badge-primary {
	@extend .badge;
	color: $blue-700;
	background: $blue-200;
}

.exds-badge-success {
    @extend .badge;
    color: $green-700;
    background-color: $green-200;
}

.exds-badge-danger {
    @extend .badge;
    color: $red-500;
    background-color: $red-100;
}

.exds-badge-info {
    @extend .badge;
    color: $purple-700;
    background-color: $purple-200;
}

.exds-badge-warning {
    @extend .badge;
    color: $yellow-800; //the design system lists the color as $yellow-700, but in the v1.0 home page mockups, this color was changed to $yellow-800 for contrast reasons
    background-color: $yellow-300;
}

.exds-badge-warm {
	@extend .badge;
	color: $orange-700;
	background: $orange-200;
}