#survey {

    .survey-container {
        background-color: $purple-700;
        border-radius: 16px;
    }
    .icon-gray-100 {
        color: $gray-100;
    }
    .btn-survey-primary {
        background-color: $gray-100;
        border-radius: 100px;
        color: $purple-700;
    }
    .btn-survey-secondary {
        background-color: $purple-700;
        border: 0px;
        color: $gray-100;
    }

    .survey-header {
        color: $gray-100;
    }
    .survey-description {
        color: $gray-100;
    }
    .survey-text-area {
        border-radius: 0px;
        border: 1px solid $blue-800;
    }


    .survey-radio {
        display: inline-block;
        text-align: center;
    }
    .survey-radio label {
        display:block;
    }

    .form-check .form-check-input {
        float: none;
        margin-left: 0;
        width: 28px;
        height: 28px;
        background-color: $blue-300;
        border: 1px solid $blue-500;
    }


    .form-check {
        padding-left: 0px;
    }
    .form-check-input:checked[type=radio] {
        background-size: auto;
        background-image: escape-svg(url("data:image/svg+xml,<svg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='4' cy='4' r='4' fill='white'/></svg>"));

    }

    .form-check-input:checked {
        background-color: $blue-700 !important;
        border-color: $blue-700;
    }

    #rateReportingSiteLink.btn:focus {
        box-shadow: none;
    }
}