/*
@styleguide

@title Component: Modal

The .modal-exds component is built alongside Bootstrap's existing <a href="https://getbootstrap.com/docs/5.1/components/modal/" target="_blank">modal</a>. In the redesigned Istation version of the modal, the .close button is repositioned to the top right of the screen, and the colors in Bootstrap's modal-content are inverted so that the custom .modal-panel class or .card class can be used to isolate and separate the content.

The modal CSS also supports a screen-wide carousel feature (See Istation home page for examples of multiple pop-up notifications displayed with this carousel).

<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Toggle complex, responsive example modal</button><br/><br/><button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2">Toggle simpler example modal</button>

<div class="modal modal-exds fade" id="exampleModal" tabindex="-1" role="dialog" aria-label="Example Modal" aria-hidden="true">
	<div class="modal-atr-controls">
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
			<svg aria-hidden="true" role="img" width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.3597 10.0068 7.1666-7.14998c.3139-.31384.4902-.7395.4902-1.18333 0-.44384-.1763-.869498-.4902-1.183337C19.2125.176313 18.7868 0 18.343 0s-.8695.176313-1.1833.490153l-7.15 7.166667L2.85966.490153C2.54582.176313 2.12017 0 1.67633 0S.806837.176313.492997.490153C.179158.803992.00284434 1.22965.00284433 1.67349c0 .44383.17631367.86949.49015267 1.18333L7.65966 10.0068l-7.166663 7.15c-.156214.155-.280204.3393-.364818.5424C.0435641 17.9023 0 18.1201 0 18.3402c0 .22.0435641.4378.128179.6409.084614.2031.208604.3874.364818.5424.154939.1562.339274.2802.542373.3648.2031.0846.42094.1282.64096.1282s.43786-.0436.64096-.1282c.2031-.0846.38744-.2086.54237-.3648l7.15004-7.1667 7.15 7.1667c.1549.1562.3392.2802.5423.3648.2031.0846.421.1282.641.1282.22 0 .4379-.0436.641-.1282.2031-.0846.3874-.2086.5423-.3648.1562-.155.2802-.3393.3649-.5424.0846-.2031.1281-.4209.1281-.6409 0-.2201-.0435-.4379-.1281-.641-.0847-.2031-.2087-.3874-.3649-.5424l-7.1666-7.15Z" fill="currentColor"/></svg>
			<br>
			<span class="close-text">ESC</span>
		</button>
	</div>
	 <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <h1 class="h2 mb-0 mt-4">This is a custom modal!</h1>
            <div class="modal-panel my-4">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <p>Notice how it uses the .modal-exds and .modal-panel classes.</p>
                        </div>
                    </div>
                </div>
            </div>
            <h1 class="h2 mb-4">Here's another way to use .modal-panel.</h1>
            <!-- there's deliberately not a "container" for this row because we want the negative gutters on the outside for this design.. -->
            <div class="row text-center">
                <div class="col-12 col-lg-4 mb-4">
                    <div class="modal-panel">
                        <h2 class="h4 text-body">Example Column</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-4 mb-4">
                    <div class="modal-panel">
                        <h2 class="h4 text-body">Example Column</h2>
                    </div>
                </div>
                <div class="col-12 col-lg-4 mb-4">
                    <div class="modal-panel">
                        <h2 class="h4 text-body">Example Column</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="text-center h6">
                        Check out this 
                        <a class="modal-content-link" href="#">custom link text</a> with .modal-content-link.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal modal-exds fade" id="exampleModal2" tabindex="-1" role="dialog" aria-label="Example Modal" aria-hidden="true">
	<div class="modal-atr-controls">
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
			<svg aria-hidden="true" role="img" width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.3597 10.0068 7.1666-7.14998c.3139-.31384.4902-.7395.4902-1.18333 0-.44384-.1763-.869498-.4902-1.183337C19.2125.176313 18.7868 0 18.343 0s-.8695.176313-1.1833.490153l-7.15 7.166667L2.85966.490153C2.54582.176313 2.12017 0 1.67633 0S.806837.176313.492997.490153C.179158.803992.00284434 1.22965.00284433 1.67349c0 .44383.17631367.86949.49015267 1.18333L7.65966 10.0068l-7.166663 7.15c-.156214.155-.280204.3393-.364818.5424C.0435641 17.9023 0 18.1201 0 18.3402c0 .22.0435641.4378.128179.6409.084614.2031.208604.3874.364818.5424.154939.1562.339274.2802.542373.3648.2031.0846.42094.1282.64096.1282s.43786-.0436.64096-.1282c.2031-.0846.38744-.2086.54237-.3648l7.15004-7.1667 7.15 7.1667c.1549.1562.3392.2802.5423.3648.2031.0846.421.1282.641.1282.22 0 .4379-.0436.641-.1282.2031-.0846.3874-.2086.5423-.3648.1562-.155.2802-.3393.3649-.5424.0846-.2031.1281-.4209.1281-.6409 0-.2201-.0435-.4379-.1281-.641-.0847-.2031-.2087-.3874-.3649-.5424l-7.1666-7.15Z" fill="currentColor"/></svg>
			<br>
			<span class="close-text">ESC</span>
		</button>
	</div>
	<div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content text-body">
			<div class="card">
				<div class="card-body">
					<h1 class="h3 mb-4">This example is simpler.</h1>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
					<div class="d-flex justify-content-center">
						<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
        </div>
    </div>
</div>

	<!-- COMPLEX, RESPONSIVE EXAMPLE MODAL WITH .MODAL-PANEL -->
	<div class="modal modal-exds fade" id="exampleModal" tabindex="-1" role="dialog" aria-label="Example Modal" aria-hidden="true">
		<div class="modal-atr-controls">
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
				<svg aria-hidden="true" role="img" width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.3597 10.0068 7.1666-7.14998c.3139-.31384.4902-.7395.4902-1.18333 0-.44384-.1763-.869498-.4902-1.183337C19.2125.176313 18.7868 0 18.343 0s-.8695.176313-1.1833.490153l-7.15 7.166667L2.85966.490153C2.54582.176313 2.12017 0 1.67633 0S.806837.176313.492997.490153C.179158.803992.00284434 1.22965.00284433 1.67349c0 .44383.17631367.86949.49015267 1.18333L7.65966 10.0068l-7.166663 7.15c-.156214.155-.280204.3393-.364818.5424C.0435641 17.9023 0 18.1201 0 18.3402c0 .22.0435641.4378.128179.6409.084614.2031.208604.3874.364818.5424.154939.1562.339274.2802.542373.3648.2031.0846.42094.1282.64096.1282s.43786-.0436.64096-.1282c.2031-.0846.38744-.2086.54237-.3648l7.15004-7.1667 7.15 7.1667c.1549.1562.3392.2802.5423.3648.2031.0846.421.1282.641.1282.22 0 .4379-.0436.641-.1282.2031-.0846.3874-.2086.5423-.3648.1562-.155.2802-.3393.3649-.5424.0846-.2031.1281-.4209.1281-.6409 0-.2201-.0435-.4379-.1281-.641-.0847-.2031-.2087-.3874-.3649-.5424l-7.1666-7.15Z" fill="currentColor"/></svg>
				<br>
				<span class="close-text">ESC</span>
			</button>
		</div>
		<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
			<div class="modal-content">
				<h1 class="h2 mb-0 mt-4">This is a custom modal!</h1>
				<div class="modal-panel my-4">
					<div class="container">
						<div class="row">
							<div class="col">
								<p>Notice how it uses the .modal-exds and .modal-panel classes.</p>
							</div>
						</div>
					</div>
				</div>
				<h1 class="h2 mb-4">Here's another way to use .modal-panel.</h1>
				<!-- there's deliberately not a "container" for this row because we want the negative gutters on the outside for this design.. -->
				<div class="row text-center">
					<div class="col-12 col-lg-4 mb-4">
						<div class="modal-panel">
							<h2 class="h4 text-body">Example Column</h2>
						</div>
					</div>
					<div class="col-12 col-lg-4 mb-4">
						<div class="modal-panel">
							<h2 class="h4 text-body">Example Column</h2>
						</div>
					</div>
					<div class="col-12 col-lg-4 mb-4">
						<div class="modal-panel">
							<h2 class="h4 text-body">Example Column</h2>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<p class="text-center h6">
							Check out this 
							<a class="modal-content-link" href="#">custom link text</a> with .modal-content-link.
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- SIMPLER EXAMPLE MODAL. Notice .card used instead of .modal-panel, so .text-body must be used to give the modal text a non-white color! -->
	<div class="modal modal-exds fade" id="exampleModal2" tabindex="-1" role="dialog" aria-label="Example Modal" aria-hidden="true">
		<div class="modal-atr-controls">
			<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
				<svg aria-hidden="true" role="img" width="21" height="21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m12.3597 10.0068 7.1666-7.14998c.3139-.31384.4902-.7395.4902-1.18333 0-.44384-.1763-.869498-.4902-1.183337C19.2125.176313 18.7868 0 18.343 0s-.8695.176313-1.1833.490153l-7.15 7.166667L2.85966.490153C2.54582.176313 2.12017 0 1.67633 0S.806837.176313.492997.490153C.179158.803992.00284434 1.22965.00284433 1.67349c0 .44383.17631367.86949.49015267 1.18333L7.65966 10.0068l-7.166663 7.15c-.156214.155-.280204.3393-.364818.5424C.0435641 17.9023 0 18.1201 0 18.3402c0 .22.0435641.4378.128179.6409.084614.2031.208604.3874.364818.5424.154939.1562.339274.2802.542373.3648.2031.0846.42094.1282.64096.1282s.43786-.0436.64096-.1282c.2031-.0846.38744-.2086.54237-.3648l7.15004-7.1667 7.15 7.1667c.1549.1562.3392.2802.5423.3648.2031.0846.421.1282.641.1282.22 0 .4379-.0436.641-.1282.2031-.0846.3874-.2086.5423-.3648.1562-.155.2802-.3393.3649-.5424.0846-.2031.1281-.4209.1281-.6409 0-.2201-.0435-.4379-.1281-.641-.0847-.2031-.2087-.3874-.3649-.5424l-7.1666-7.15Z" fill="currentColor"/></svg>
				<br>
				<span class="close-text">ESC</span>
			</button>
		</div>
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content text-body">
				<div class="card">
					<div class="card-body">
						<h1 class="h3 mb-4">This example is simpler.</h1>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
						<div class="d-flex justify-content-center">
							<button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

*/


// This is an Istation custom class;
// it repositions Bootstrap's .close
// and inverts the colors in Bootstrap's .modal-content.
// It's intended to be used together with the custom .modal-panel class 
// to break up modal content into cards or panels as shown in the mocked up designs.
.modal-exds {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

	//Custom class for repositioning the close icon on the right
	//atr = absolute, top, right
	.modal-atr-controls { 
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	//.... and adding padding at the top to keep modal content from overlapping the giant 'x'
	.modal-atr-controls + .modal-dialog {
		padding-top: 71px;
	}

    .modal-content {
        display: flex;
        flex-flow: column nowrap;
        background: transparent;
        color: $white;
        border: none;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        width: 100%;
        border: 0;
    }
    .modal-header {
        padding: 0.75rem 0.75rem 0 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        & ~ .modal-body {
            margin: 0 auto;
            flex: 1 1 auto;
            padding-bottom: 3rem;
            overflow: hidden;
        }
    }

    .modal-footer {
        display: none;

        p {
            color: transparentize($white, 0.25);
        }
    }

    .carousel-control {
        position: relative;
        height: 3.125rem;
        width: 100%;
        left: 0;
        pointer-events: none;

        button {
            pointer-events: all;
            display: inline-block;
            padding: 0.75rem 0.75rem;
            width: 3.125rem;
            height: 100%;
            background-color: $blue-800;
            border-radius: 50%;
            margin: 0 10px;
        }
        &-prev,
        &-next {
            position: relative;
            opacity: 1;
        }

        &-prev-icon,
        &-next-icon {
            width: 1.5rem;
            height: 1.5rem;
        }
        &-disabled {
            color: $grayblue-400;
            background-color: $grayblue-500 !important;
        }
    }

    .notice-list {
        width: 100%;
        max-width: 90%;
        margin: 0 auto;

        .notice-level {
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
    
    }
    .message-content {
        line-height: 1.6rem;
    }

    .btn-close {
        width: auto;
        height: auto;
        background: none;
        color: $grayblue-100;
        text-shadow: none;
        opacity: 1;
        z-index: $zindex-modal + 1;
        padding: 1.5rem;
        .icon-close {
            width: 20px;
            height: 20px;
        }
        .close-text {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        .notice-list {
            max-width: 48rem;

            h1{
                display: block;

                & ~ p {
                    display: block;
                }
            }
        }
        .modal-header{
            padding: 1.5rem 1.5rem 0 0;
        }

        .notice-list .modal-panel {
            background: $white;
            max-height: 65vh;
            width: 516px;

            &-content {
                max-height: 45vh;
            }
            .btn-cta {
                background-color: $primary;
                border-color: $primary;
                color: $white;
            }
            ol {
                display: block;
            }
        }

        .carousel-control {
            height: 4rem;
            pointer-events: none;
            top: 50vh;
            position: fixed;
    
            button {
                padding: 1.25rem;
                width: 4rem;
            }
            &-prev,
            &-next {
                position: absolute;
            }

            &-prev-icon,
            &-next-icon {
                width: 1.5rem;
                height: 1.5rem;
            }    
        }

        .modal-footer {
            display: flex;
            flex-flow: column nowrap;
            position: relative;
            padding: .75rem 0;
            
            &::before {
                content: "";
                position: absolute;
                top: -2rem;
                left: 0;
                width: 100%;
                height: 2rem;
                background-image: linear-gradient(to bottom, transparent, rgba(30,56,74,0.35));
            }
        }
    
        
    }
    @include media-breakpoint-up(lg) {
        .notice-list .modal-panel {
            width: 748px;
        }
        .carousel-control {
            height: 6rem;
            pointer-events: none;
    
            button {
                padding: 2rem;
                width: 6rem;
            }

            &-prev-icon,
            &-next-icon {
                width: 2rem;
                height: 2rem;
            }    
        }
        .btn-close .close-text {
            display: block;
        }
    }
    
}

// This is an Istation custom class 
// that wraps content in a white card or panel.
.modal-panel {
    background: $white;
    color: $gray-700;
    border-radius: map-get($spacers, 4);
    padding: map-get($spacers, 6) .625rem;
    max-height: 55vh;

    &-content {
        max-height: 35vh;
        overflow-y: auto;
    }

    .btn-cta {
        background-color: $primary;
        color: $white;

    }

}

// This is an Istation custom class
// for styling any links that are inside .modal-content 
// but outside of .modal-panel.
.modal-content-link {
    color: #71a8c2;
    text-decoration: underline;
    &:hover {
        color: #71a8c2;
        text-decoration: underline;
    }
}

// This is an Istation custom class
// for styling the 'ESC' text next to the close 'X' icon
.btn-close-text,
.close-text {
    color: $grayblue-100;
    font-weight: 500;
    font-size: $font-size-md !important;
}
// This is an Istation custom class for styling the 'dismiss' button below a notification
.btn-dismiss {
    color: $blue-700;
    text-decoration: underline;
    display: inline-block;
    background-color: transparent;
    border: none;
}

