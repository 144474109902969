@import 'css_variables';
@import 'badge';
@import 'buttons';
@import 'common';
@import 'layout';
@import 'modals';
@import 'table';
@import 'scrollbar';
@import 'typography';
@import 'utils';

:root {
  --dialog-backdrop-z-index: 30;
}
