



/*
@styleguide

@title Component: Heading with subhead

A line of large, bold header text than can include a smaller line of lighter header text after it. Font sizes and weights are specific and come from Figma (home page mockup/page title). Used for two-line page headings, like the "[Campus Name] / Progress as of [date]" text on the home page.

<h1 class="heading-heading">Some Campus</h1><small class="heading-subhead">Progress as of January 13, 2023</small>

    <h1 class="heading-heading">Some Campus</h1>
    <small class="heading-subhead">Progress as of January 13, 2023</small>

*/

.heading-heading {
    font-size: $font-size-base * 1.5;
    font-weight: 700;
}
.heading-subhead {
    font-size: $font-size-base * 1.125;
    display: block;
    font-weight: 400;
}