@use 'variables';

.badge {
  border-radius: 100px;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: variables.$mainText;
  font-weight: 700;

  &__primary {
    text-transform: uppercase;
    background-color: variables.$greyColor;
  }

  &--pink {
    background-color: #f5c6ef;
  }

  &--orange {
    background-color: #ffb58b;
  }

  &--blue {
    background-color: #aeb6f1;
  }

  &__secondary {
    background-color: variables.$greyColor;
  }
}
