.filters {
  &-scrollbar {
    .ng-scrollbar-wrapper[deactivated=false][dir=ltr] {
      scrollbar-y.scrollbar-control {
        right: -10px;
      }
    }
  }
}

*::-webkit-scrollbar {
  width: 0.375rem;
  padding: 2px;
}

*::-webkit-scrollbar-track {
  padding: 2px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}
