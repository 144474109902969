@use '@angular/material' as mat;
@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography:
      mat.define-typography-config(
        $font-family: inherit,
      ),
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

@import './common-styles/ees/scss/exds-styles';
@import 'common-styles';

:root {
  --mat-expansion-header-hover-state-layer-color: initial;
  --mat-expansion-header-focus-state-layer-color: initial;
  --mat-menu-item-hover-state-layer-color: transparent;
  --mat-option-selected-state-layer-color: transparent;
}

html,
body {
  height: 100%;
}

body,
input {
  font-family: Raleway, serif;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
