@use 'variables';

@mixin btnStyles($color, $bgColor) {
  color: $color;
  border: 1px solid $color;
  background-color: $bgColor;
  border-radius: 50px;

  &:hover {
    color: $bgColor;
    background-color: $color;
    border-color: $bgColor;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  border: none;
  font-family: inherit;
  color: inherit;
  transition: all 0.3s ease-in-out;

  &--md {
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 600;
    padding: 0.5rem 0.75rem;
  }

  &--lg {
    font-size: 1.25rem;
    line-height: 1.375rem;
    font-weight: 700;
    padding: 1rem 1.5rem;
    gap: 1rem;
  }

  &--primary {
    @include btnStyles(white, variables.$mainColor);

    border-color: transparent;
  }

  &--outline {
    @include btnStyles(variables.$mainColor, white);
  }
}

.btn.jit-primary-btn {
  @include btnStyles(white, variables.$mainColor);

  border-color: transparent;

  &:disabled {
    background-color: variables.$grey;
    color: #868686;
  }
}

.jit-primary-btn-outline {
  @include btnStyles(variables.$mainColor, white);
}

.jit-secondary-btn {
  @include btnStyles(white, variables.$secondaryColor);
}

.jit-secondary-btn-outline {
  @include btnStyles(variables.$secondaryColor, white);
}

.jit-def-button {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #1A396A;
  background: #fff;
  cursor: pointer;
  color: #1A396A;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
